const appName = 'ReactApp';
const version = '1.5.0';
const environment = process.env.REACT_APP_ENVIRONMENT;

const defaultConfig = {
  version: version,
  apiPort: 3000,
  appName: appName,
  apiHost: process.env.REACT_APP_API_HOST,
  mainHost: process.env.REACT_APP_MAIN_HOST,
  journeyHost: process.env.REACT_APP_JOURNEY_URL,
  bugsnagApiKey: '5a9be520a91e246a614f6704e19201b0',
  app: {
    title: 'Gymleads Sales',
    description: 'Gymleads Sales',
  },
};
let config = {};

if (environment === 'development' || environment === 'test') {
  config = Object.assign({
    ...defaultConfig,
    facebookAppId: '454583874908968',
    intercom_app_id: '',
    debug: true,
  });
}

if (environment === 'staging') {
  config = Object.assign({
    ...defaultConfig,
    facebookAppId: '454583874908968',
    intercom_app_id: '',
    debug: true,
  });
}

if (environment === 'production') {
  config = Object.assign({
    ...defaultConfig,
    facebookAppId: '344539112599083',
    intercom_app_id: 'f8f1vqq3',
    debug: false,
  });
}

export default config;
